<template>
    <div class="main_content">
        <div class="main_content_children">
            <div class="main_content_children_header">
                <div class="main_content_children_header_left" @mouseleave="leveframe()">
                    <div v-for="(item, index) in list" :key="item.id" style="margin: 5px 0px;">
                        <div style="display: flex; align-items: center;" @mouseenter="popframe(item.id)"
                            >
                            <div class="title">
                                {{ item.name }}
                            </div>
                            <div v-for="items in item.children.slice(0, 2)" :key="items.id" style="display: flex;"
                                class="text">
                                <div>
                                    {{ items.name }}
                                </div>

                            </div>
                            <div style="display: flex; align-items: center;">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>
                        <div class="framecontent" :style="{ top: `${index * 3}em` }"
                            :class="state == item.id ? 'active' : ''">
                            <div v-for="item in listchildren">
                                <div class="framecontent_text">
                                    {{ item.name }}
                                </div>
                                <div style="display: flex; flex-wrap: wrap;">
                                    <div v-for="items in item.children" style="width: 8em;margin: 10px 0px;">
                                        {{ items.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main_content_children_header_center">

                    <el-carousel :interval="5000" arrow="always" style="min-height: 28em;">
                        <el-carousel-item v-for="( item, index ) in  Rotations " :key="item" style="min-height: 28em;">
                            <div class="div1" :style="{ 'backgroundImage': 'url(' + item.path + ')' }">
                                <img :src="item.path" alt="" />
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="main_content_children_header_right">
                    <div class="userinfo">
                        <div class="userinfo_header">
                            <div class="userinfo_header_img">
                                <div>
                                    <img src="../assets/leaning/header.png" alt=""
                                        style="width: 30px; height: 30px; border-radius: 50%;">
                                </div>
                                <div style="margin: 0px 6px;">
                                    admin
                                </div>
                            </div>
                            <div style="display: flex; align-items: center;">个人中心<el-icon>
                                    <ArrowRight />
                                </el-icon> </div>
                        </div>
                        <div class="userinfo_header_text">
                            <div class="userinfo_header_text_left">
                                <div>2</div>
                                <div>本周学习</div>
                            </div>
                            <div class="userinfo_header_text_center">
                                <div>
                                    0
                                </div>
                                <div>
                                    学习计划
                                </div>
                            </div>
                            <div class="userinfo_header_text_right">
                                <div>0</div>
                                <div>我的关注</div>
                            </div>
                        </div>
                    </div>
                    <div class="operate">
                        <div class="operate_Strategy">
                            <div>经营攻略</div>

                        </div>
                        <div>
                            <el-carousel style="min-height: 13em;">
                                <el-carousel-item v-for="(item, index) in 4" :key="item" style="min-height: 13em;">
                                    <div class="carousel_title">
                                        0 {{ index + 1 }}基础建设
                                    </div>
                                    <div class="carousel_text">
                                        <p>如果学会成长</p>
                                        <p>如何月入100万</p>
                                        <p>如何白日做梦</p>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>

            </div>
            <div class="rule_popular">
                <div class="rule_popular_left">
                    <div class="rule_popular_left_img">
                        <img src="../assets/leaning/rule.png" alt=""
                            style="width: 20px; height: 20px; object-fit: contain; margin-right: 20px;"> <span
                            style="font-size: 1.5em; ">规则早知道</span>
                    </div>
                    <div class="rule_content">
                        <div class="rule_content_text" v-for="item in 3" @click="textdetial(id)">
                            <div class="rule_content_text_left">
                                <img src="../assets/leaning/rule2.png" alt="" style="width: 100%; height: 100%; object-fit: contain;">
                            </div>
                            <div class="rule_content_text_right">
                                <div style="font-size: 1.3em;">
                                    重点平台规则
                                </div>
                                <div class="rule_type">
                                    <div>平台认知能力</div>
                                    <div>基础考核规则</div>
                                    <div>初阶</div>
                                </div>
                                <div class="rule_statistics" style="display: flex; justify-content: space-between;">
                                   <div style="display: flex;">
                                        <div>999人学习</div>
                                        <div style="margin: 0px 10px;">100评论</div>
                                   </div>
                                    <div>11月10日</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rule_popular_right">
                    <div class="rule_popular_right_img">
                            <div>
                                <img src="../assets/leaning/hot.png" alt=""
                                    style="width: 20px; height: 20px; object-fit: contain; margin-right: 20px;"> <span
                                    style="font-size: 1.5em; ">热门问答</span>
                            </div>
                            <div style="display: flex; justify-content: end; width: 80%;">
                                更多<el-icon><ArrowRight /></el-icon>
                            </div>
                    </div>
                    <div class="rule_content">
                        <div class="rule_content_text" v-for="item in 3" style="padding:10px 20px;">
                           <div>
                            <div style="display: flex; align-items: center;">
                                <div style="width: 2em; height: 2em;text-align: center;line-height: 2em; background-color: rgba(246,69,69,.1); color: #f64545;">新</div>
                                <div style="font-size: 1.3em;">企业店铺主体如何变更？</div>
                            </div>
                            <div style="line-height: 18px;color: #8c8c8c;font-size: 1.1em;">商家需要致电商家客服热线-4006229068并提供一下材料即可变更： ①新主体的营业执照和法人身份证；②确认函；③承诺函；④京东支付服务协议；⑤新旧关系证明。</div>
                            <div style="display: flex; justify-content: space-between; margin: 10px 0px;">
                                <div>
                                    已帮助472人
                                </div>
                                <div>08月07日</div>
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leanplan">
                <div>
                    学习计划
                </div>
                <div>
                    查看更多<el-icon><ArrowRight /></el-icon>
                </div>
            </div>
            <div class="plan_content">
                <div class="plan_content_child" v-for="item in 8" @click="textdetial(id)">
                    <div class="plan_content_child_img">

                    </div>
                    <div class="plan_content_child_text">
                        <div class="plan_content_child_title">
                            CPS新商家必修课
                        </div>
                        <div class="plan_content_child_day">
                            <div>999人已加入</div>
                            <div>2024-12-29截至</div>
                        </div>
                        <div class="plan_content_child_application">
                            <div>共10节课</div>
                            <div>立即报名</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leanplan">
                <div>
                    新商入门
                </div>
                <div>
                    查看更多<el-icon><ArrowRight /></el-icon>
                </div>
            </div>
            <div class="plan_content">
                <div class="plan_content_child" v-for="item in 4" @click="textdetial(id)">
                    <div class="plan_content_business_img">

                    </div>
                    <div class="plan_content_child_text">
                        <div class="plan_content_child_title">
                            CPS新商家必修课
                        </div>
                        <div class="plan_content_child_day">
                            <div>999人已加入</div>
                            <div>2024-12-29截至</div>
                        </div>
                        <div class="plan_content_child_application">
                            <div>共10节课</div>
                            <div>立即报名</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="leanplan">
                <div>
                    商品管理
                </div>
                <div>
                    查看更多<el-icon><ArrowRight /></el-icon>
                </div>
            </div>
            <div class="plan_content">
                <div class="plan_content_child" v-for="item in 4" @click="textdetial(id)">
                    <div class="plan_content_commodity_img">

                    </div>
                    <div class="plan_content_child_text">
                        <div class="plan_content_child_title">
                            CPS新商家必修课
                        </div>
                        <div class="plan_content_child_day">
                            <div>999人已加入</div>
                            <div>2024-12-29截至</div>
                        </div>
                        <div class="plan_content_child_application">
                            <div>共10节课</div>
                            <div>立即报名</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-backtop :right="100" :bottom="100" />
</template>

<script setup >
import { ref, reactive } from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const list = ref([
    {
        id: '1',
        name: "招商入住",
        children: [
            {
                id: '1-1',
                name: "平台介绍"
            },
            {
                id: '1-2',
                name: "入驻流程"
            },
            {
                id: '1-3',
                name: "入驻流程"
            },
            {
                id: '1-4',
                name: "入驻流程"
            }
        ]
    },
    {
        id: '2',
        name: "  商品运营",
        children: [
            {
                id: '2-1',
                name: "新品运营"
            },
            {
                id: '2-2',
                name: "爆款打造"
            }
        ]
    },
    {
        id: '3',
        name: "仓配客售",
        children: [
            {
                id: '3-1',
                name: "店铺星级"
            },
            {
                id: '3-2',
                name: "售前服务"
            }
        ]
    },
    {
        id: '4',
        name: "仓配客售",
        children: [
            {
                id: '3-1',
                name: "店铺星级"
            },
            {
                id: '3-2',
                name: "售前服务"
            }
        ]
    },
    {
        id: '5',
        name: "仓配客售",
        children: [
            {
                id: '3-1',
                name: "店铺星级"
            },
            {
                id: '3-2',
                name: "售前服务"
            }
        ]
    },
    {
        id: '6',
        name: "仓配客售",
        children: [
            {
                id: '3-1',
                name: "店铺星级"
            },
            {
                id: '3-2',
                name: "售前服务"
            }
        ]
    },
    {
        id: '7',
        name: "仓配客售",
        children: [
            {
                id: '3-1',
                name: "店铺星级"
            },
            {
                id: '3-2',
                name: "售前服务"
            }
        ]
    },
    {
        id: '8',
        name: "仓配客售",
        children: [
            {
                id: '3-1',
                name: "店铺星级"
            },
            {
                id: '3-2',
                name: "售前服务"
            },
            {
                id: '3-2',
                name: "售前服务"
            },
            {
                id: '3-2',
                name: "售前服务"
            }
        ]
    }
])
const Rotations = ref([
    { path: require('../assets/login_bg.png') },
    { path: require('../assets/login_bg.png') },
    { path: require('../assets/login_bg.png') }
])
const state = ref()
let listchildren = ref([])
const popframe = (id) => {
    state.value = id
    // console.log(id);
    listchildren.value = list.value.filter(item => {
        return item.id == id
    })
    console.log(listchildren.value)
}
const leveframe = () => {
    state.value = ""
}
const textdetial = (id)=>{
    router.push({
        path:"/coursehour"
    })
}
</script>

<style scoped lang="scss">
.main_content {
    width: 100%;
    min-height: 1600px;
    display: flex;
    justify-content: center;

    .main_content_children {
        width: 70%;
        // background-color: gainsboro;
        margin-top: 1em;

        .main_content_children_header {
            display: flex;

            .main_content_children_header_left,
            .main_content_children_header_right {
                flex: 1;
                border-radius: 10px;

                .userinfo {
                    margin-left: 10px;
                    min-height: 10em;
                    background-color: white;
                    padding: 10px;

                    .userinfo_header {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        height: 60px;
                        align-items: center;
                        padding: 0px 30px;

                        .userinfo_header_img {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .userinfo_header_text {
                        display: flex;
                        padding: 10px 0px;

                        .userinfo_header_text_center,
                        .userinfo_header_text_right,
                        .userinfo_header_text_left {
                            flex: 1;
                            text-align: center;

                            div:nth-child(1) {
                                font-size: 1.5em;
                            }
                        }
                    }
                }

                .operate {
                    min-height: 17em;
                    background: url('../assets/leaning/bg2.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: 10px;
                    padding: 10px 20px;

                    // padding: ;
                    :deep(.el-carousel__container) {
                        height: 13em !important;
                    }

                    .operate_Strategy {
                        font-size: 1.2em;
                        font-family: '黑体';
                    }

                    .carousel_title {
                        height: 4em;
                        line-height: 4em;
                        font-size: 1.3em;
                    }

                    .carousel_text {
                        line-height: 2em;
                    }
                }
            }

            // background-image: ;
            .main_content_children_header_center {
                flex: 2;
                border-radius: 10px;
            }

            position: relative;

            .main_content_children_header_left {
                min-height: 28em;
                background-color: white;
                margin-right: 1em;
                padding: 10px 15px;
                cursor: pointer;

                .title {
                    font-size: 1.4em;
                    font-weight: 600;
                    font-family: '宋体';
                    width: 6em;
                    height: 2em;
                    display: flex;
                    align-items: center;
                }

                .text {
                    width: 6em;
                    font-size: 1.2em;
                    display: flex;
                    align-items: center;
                    height: 2em;
                }
                .framecontent:hover {
                    display: block;
                }
                .framecontent {
                    position: absolute;
                    left: 28em;
                    width: 250px;
                    min-height: 30px;
                    background-color: white;
                    border-radius: 10px;
                    padding: 10px;
                    padding-left: 25px;
                    display: none;

                    .framecontent_text {
                        font-size: 1.3em;
                        margin: 5px 0px;
                    }
                }
            }

        }

        .rule_popular {
            display: flex;

            .rule_popular_left,
            .rule_popular_right {
                flex: 1;
            }
            .rule_popular_right{
                margin-left: 10px;
            }
            .rule_popular_left{
                margin-right: 10px;
            }
            .rule_popular_left ,.rule_popular_right{
                .rule_popular_left_img {
                    display: flex;
                    align-items: center;
                    height: 80px;

                }
                .rule_popular_right_img{
                    display: flex;
                    align-items: center;
                    height: 80px;
                    
                }
                .rule_content {
                    min-height: 32em;
                    background-color: white;
                    padding: 10px;
                    .rule_content_text{
                        display: flex;
                        min-height: 10em;
                        align-items: center;
                        border-bottom: 1px solid gainsboro;
                        .rule_content_text_left{
                            flex:2;
                        }
                        .rule_content_text_right{
                            flex: 5;
                            padding-left: 30px;
                            line-height: 45px;
                            .rule_type{
                                display: flex;
                                div{
                                    margin-right: 10px;
                                    width: 6em;
                                    height: 2em;
                                    background-color: rgb(235,240,255);
                                    color: #7395fc;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                            .rule_statistics{
                                display: flex;
                                height: 3em;

                            }
                        }
                    }
                }

            }

        }
        .leanplan{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            padding: 20px 10px;
            div:nth-child(1){
                font-size: 1.5em;
                font-family: '黑体';
            }
            div:nth-child(2){
                display: flex;
                align-items: center;
            }

        }
        .plan_content{
            width: 100%;
            min-height: 300px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .plan_content_child{
                width: 25em;
                height: 26em;
                background-color: white;
                border-radius: 5px;
                margin: 10px 0px;
                cursor: pointer;
                .plan_content_child_img{
                    width: 100%;
                    height: 14em;
                    background: url('../assets/leaning/rule2.png') no-repeat;
                    background-size: 100% 100%;
                }
                .plan_content_business_img{
                    width: 100%;
                    height: 14em;
                    background: url('../assets/leaning/leanimg2.png') no-repeat;
                    background-size: 100% 100%;
                }
                .plan_content_commodity_img{
                   
                    width: 100%;
                    height: 14em;
                    background: url('../assets/leaning/leanimg3.png') no-repeat;
                    background-size: 100% 100%;
                }
                
                .plan_content_child_text{
                    padding: 5px 15px;
                    .plan_content_child_title{
                        font-size: 1.4em;
                        font-family: '黑体';
                        margin: 10px 0px;
                        color: #262626;
                        font-weight:600;

                    }
                    .plan_content_child_day{
                        display: flex;
                        justify-content: space-between;
                        color: #8c8c8c;
                        font-size: 14px;
                        margin: 10px 0px;
                    }
                    .plan_content_child_application{
                        display: flex;
                        justify-content: space-between;
                        height: 6em;
                        align-items: center;
                        color: #8c8c8c;
                        font-size: 14px;
                        div:nth-child(2){
                            width: 87px;
                            height: 31px;
                            border: 1px solid #3768fa;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color:#3768fa ;
                        }
                    }
                }
            }
            .plan_content_child:hover {
                box-shadow: 1px 1px 10px rgb(214, 214, 214);
                transform: translateY(-5px);
            }
        }
    }
}

.active {
    display: block !important;
    z-index: 999;
}

.main_content_children_header_center {
    .el-carousel__item h3 {
        color: #475669;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
        text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
}

.demonstration {
    color: var(--el-text-color-secondary);
}



.div1 {
    width: 100%;
    height: 100%;

    img {
        width: 100%;
        height: 100%;
    }
}</style>